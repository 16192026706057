.about .about-title{
    font-size: 47px;
    color: #FFFFFF;
    font-family: Roboto-Bold;
}
.about .about-title span{
    font-size: 47px;
    color: #D60012;
    font-family: Roboto-Bold;
}

.about .para {
    font-family: Roboto-Regular;
    font-size: 16px;
    color: #FFFFFF;
}

.about .inner-container {
    padding: 68px 32px 68px 84px;
}

.about .right-imgbox {
    margin: 55px 0px;
}
.about-newarrival .right-imgbox1 img {
    height: 213px;
    object-fit: cover;
}
.about-newarrival .right-imgbox2 img {
    height: 401px;
    object-fit: cover;
}
/* new arrival section  */
.about-newarrival .about-title {
    font-size: 47px;
    color: #FFFFFF;
    font-family: Roboto-Bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: pre-wrap;
}
.about-newarrival .about-title span{
    font-size: 47px;
    color: #D60012;
    font-family: Roboto-Bold;
}

.about-newarrival .para {
    font-family: Roboto-Regular;
    font-size: 14px;
    color: #FFFFFF;
}
.about p {
    font-size: 14px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 15;
    margin-bottom: 0;
}

.about-newarrival .inner-container {
        padding: 68px 40px 68px 5px;
}

.about-newarrival .right-imgbox1 {
    margin: 55px 0px;
}
.about-newarrival .right-imgbox2 {
    margin: 90px 0px;
}
/* new arrival section end  */

/* out team  */
.ourTeam{
    margin-top: 0px;
    margin-bottom: 88px;
}
.ourTeam .title{
    font-size: 47px;
    color: #FFFFFF;
    font-family: Roboto-Bold;
    text-align: center;
    margin-bottom: 50px;
}
.ourTeam .title span{
    font-size: 47px;
    color: #D60012;
    font-family: Roboto-Bold;
}
.ourTeam .team-detail {
    padding: 0px 35px;
}
.ourTeam .team-detail .inner-container {
    background-color: #D60012;
    color: #FFFFFF;
    text-align: center;
    padding: 6px;
    position: relative;
    bottom: 20px;
}

.ourTeam .team-detail .inner-container .title {
    font-size: 17px;
    font-family: Roboto-Bold;
    color: #ffffff;
    margin: 0px;
    text-transform: capitalize;
}

.ourTeam .team-detail .inner-container .sub-title {
    font-size: 14px;
    font-family: Poppins-Regular;
    color: #ffffff;
    margin: 0px;
    margin-top: 5px;
}

/* our team end  */
/* media queries */
@media only screen and (max-width: 1366px){
}
@media only screen and (max-width: 1280px){
}
@media only screen and (max-width: 1100px){
}
@media only screen and (max-width: 1024px){
}
@media only screen and (max-width: 991px){
}
@media only screen and (max-width: 820px){
    .about {
        padding: 50px 0px;
    }
    .ourTeam .team-detail {
        padding: 0;
    }
    .about .inner-container {
        padding: 68px 32px 0px 0px;
    }
    .AddsBanner-container .inner-container {
        padding: 46px 0px 80px 50px;
    }
    .about-newarrival .inner-container {
        padding: 68px 0px 0px 0px;
    }
    .about .about-title {
        font-size: 24px;
    }
}
@media only screen and (max-width: 768px){
}
@media only screen and (max-width: 575px){
    .about .inner-container {
        padding: 0px 0px 0px 0px;
    }
    .about-newarrival .about-title {
        font-size: 25px;

    }
    .ourTeam .title span {
        font-size: 23px;

    }
    .ourTeam .title {
        font-size: 19px;
    }
}
/* 
@media (max-width: 1150px) {
    .about .para {
        font-family: Roboto-Regular;
        font-size: 14px;
        color: #FFFFFF;
    }
    .about .inner-container {
        padding: 68px 28px 45px 34px;
    }
    .about-newarrival .para {
        font-size: 14px;

    }
}

@media (max-width: 768px) {
    .about .para {
        font-size: 12px;
    }
    .about-newarrival .para {
        font-size: 12px;
    }
    .ourTeam .team-detail {
        padding: 0px 10px;
    }
    .ourTeam .team-detail .inner-container .sub-title {
        font-size: 10px;
    }
    .ourTeam .team-detail .inner-container .title {
        font-size: 12px;
    }
} */