
.modal-open {
    overflow: hidden;
    padding-right: 0px !important;
}

.sizeChart-modal .modal-body{
    padding: 0px !important;
}

.sizeChart-modal .modal-content {
    background-color: #0D0D0D !important;
    margin-top: 20%;
    border: 2px solid #898989d4;
}
.sizeChart-modal .modal-content .table-dark td, .table-dark th, .table-dark thead th {
    border-color: #0D0D0D !important;
    background-color: #0D0D0D;
    text-align: center !important;
}

.sizeChart-modal .modal-content .table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: #0D0D0D !important;
    color: var(--bs-table-striped-color);
    border-right: 1px solid #0D0D0D !important;
    text-align: center !important;
}

.modal-dialog {
    max-width: 80%;
    margin: 1.75rem auto;
}

.sizeChart-modal .modal-content .table {
    margin-bottom: 0rem;
}

.sizeChart-modal .modal-content .image-box {
    width: 292px;
}

.sizeChart-modal .modal-content .closeIcon {
    position: absolute;
    /* left: 0; */
    right: -8px;
    top: -8px;
    color: #ffff;
    font-size: 18px;
    z-index: 100;
}

.sizeChart-modal tr {
        padding: 13px 10px !important;
}
.sizeChart-modal tbody, td, tfoot, th, thead, tr {
    padding: 13px 10px !important;
}

.sizeChart-modal thead, td, tfoot, th, thead, tr {
    padding: 13px 10px !important;
}
.sizeChart-modal .table-dark {
    --bs-table-striped-bg: #707070 !important;
}

/* address  */
.address-modal{
    width: 30%;
}
.address-modal input {
    width: 100%;
    padding: 10px 10px;
    background-color: transparent;
    border: 1px solid #d4001c;
    color: #ffffff;
    border-radius: 5px;
    position: relative;
}
.address-modal .saveBtn {
    color: #fff;
    padding: 10px 50px;
    background-color: #D60012;
}
.address-modal .modal-content {
    background-color: #0D0D0D !important;
    margin-top: 10%;
    border: 1px solid #898989d4;
    color: #ffff;
}
.address-modal.modal-content .table-dark td, .table-dark th, .table-dark thead th {
    border-color: #0D0D0D !important;
    background-color: #0D0D0D !important;
    text-align: center !important;
}

.address-modal .modal-content .table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: #0D0D0D !important;
    color: var(--bs-table-striped-color);
}

.address-modal .modal-dialog {
    max-width: 80%;
    margin: 1.75rem auto;
}

.address-modal .modal-content .table {
    margin-bottom: 0rem;
}

.address-modal .modal-content .image-box {
    width: 285px;
} 

.address-modal .modal-content .closeIcon {
    position: absolute;
    /* left: 0; */
    right: -8px;
    top: -8px;
    color: #ffff;
    font-size: 18px;
}

.address-modal tr {
        padding: 13px 10px !important;
}
.address-modal tbody, td, tfoot, th, thead, tr {
    padding: 13px 10px !important;
}

.address-modal thead, td, tfoot, th, thead, tr {
    padding: 13px 10px !important;
}
.profile-icon-password {
    color: #6b0009;
    position: absolute;
    top: 50px;
    right: 35px;
}
/* media queries */
@media only screen and (max-width: 1366px){
}
@media only screen and (max-width: 1280px){
}
@media only screen and (max-width: 1100px){
}
@media only screen and (max-width: 1024px){
}
@media only screen and (max-width: 991px){
}
@media only screen and (max-width: 820px){
}
@media only screen and (max-width: 768px){
}
@media only screen and (max-width: 575px){
}
@media (max-width: 1150px) {
    .sizeChart-modal .modal-content .image-box {
        width: 285px;
        overflow: hidden;
    }
}

@media (max-width: 1024px) {

}
