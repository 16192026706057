.Contact input {
    margin-top: 22px;
}
.Contact ::placeholder {
    color: #0D0D0D !important;
    font-size: 14px !important;
}
.Contact .dark_black_div{
    background-color: #0D0D0D;
    color: white;
}
.Contact .red_div {
    background-color: #D60012;
    /* height: 500px; */
}
.Contact .mb-280{
    margin-bottom: 320px;
}
.Contact .form_box{
    background-color: white;
    width: 650px;
    margin: auto;
    padding: 2.5rem;
    position: relative;
    top: -50%;
}
.Contact .pos-rel{
    position: relative;
}
.Contact .font-roboto-reg{
    font-family: Roboto-Regular;
}
.Contact .font-roboto-bold{
    font-family: Roboto-Bold;
}
.Contact .mt-4rem{
    margin-top: 4rem!important;
}
.Contact .text-right{
    text-align: right;
}
.Contact .txt-area{
    resize: none;
}
.Contact .view_more{
    background-color: #D60012;
    color: white;
    padding: 7px 73px;
    border-radius: 0px;
}
.Contact .border-red{
    border: 1px solid transparent;
    float: right;
}
.Contact .border-red:hover{
    border-color: red!important;
}
.Contact .border-red:hover .Contact .view_more {
    position: relative;
    top: -3px;
    left: -4px;
}
.Contact .btn:hover {
    color: white;
}
.Contact .f-32{
    font-size: 32px;
}
.Contact .f-14{
    font-size: 14px;
}
.Contact .f-15{
    font-size: 15px;
}
.Contact select.form-control { -webkit-appearance: menulist; }
.Contact .row{
--bs-gutter-x: 0rem!important;
    margin-right: 0!important;
    margin-left: 0!important;
}

.Contact .btn-primary {
    border-radius: unset !important;
    color: #fff !important;
    background-color: #d60012 !important;
    border-color: #d60012 !important;
    padding: 8px 64px !important;
    margin-top: 29px;
    font-size: 14px;
    font-family: Roboto-Regular;
    float: right;
}

.Contact button.btn.btn-primary {
    position: relative;
    z-index:2;
}

.Contact button.btn.btn-primary:before {
    content: '';
    background: transparent;
    width: 100%;
    height: 100%;
    border: 2px solid #d60012;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.6s;
    z-index:-1;
}

.Contact button.btn.btn-primary:hover:before {
    left: 12px;
    top: 12px;
}

/* media queries */
@media only screen and (max-width: 1366px){
}
@media only screen and (max-width: 1280px){
}
@media only screen and (max-width: 1100px){
}
@media only screen and (max-width: 1024px){
}
@media only screen and (max-width: 991px){
}
@media only screen and (max-width: 820px){
}
@media only screen and (max-width: 768px){
}
@media only screen and (max-width: 575px){
    .Contact .form_box {
        width: 100%;
        padding: 0;
       
    }
    .Contact .f-32 {
        font-size: 18px;
    }
    .contact-btn {
        padding-bottom: 26px;
    }
    .Contact .btn-primary {
        float: inherit;
    }
}
/* @media only screen and (max-width: 990px){
    .res-pad{
        padding: 0.5rem!important;
    }
    .Contact .mt-4rem{
        margin-top: 2rem!important;
    }
    .Contact .form_box {
        padding: 1.3rem 0.3rem !important;
        top: -50%!important;
    }
    .Contact .f-32 {
        font-size: 20px;
    }
    .p-res-contact-form{
        padding: 1rem!important;
    }
    .Contact .media-mt-4{
        margin-top:1.5rem;
    }
    .Contact .form_box {
        width: auto;
        top: -56%;
    }
    .Contact .red_div {
        height: 350px;

    }
} */


.Contact input[type=number]::-webkit-inner-spin-button, 
.Contact input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}