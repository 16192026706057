/* Begin of Header Css  */
/* .nav-bottom li.dropdown:hover .dropdown-menu {
    display: block;
} */

/* .nav-bottom .custom-toggle::after {
    opacity: 0px !important;
} */

 /* .navbar-expand-md .navbar-nav .dropdown-menu {
      right: 10%;
      left: unset;
      top: 59%;
     }
li.ij .dropdown-toggle::after {
      display: none;
     } */

.flex-style {
    display: flex;
    justify-content: center;
    align-items: center;
}
.navbar-right {
    align-items: center;
}

/* .profile-header .navbar-expand-md .navbar-nav  */

.profile-header  .dropdown-menu {
      right: 10%;
      left: unset;
      top: 77%;
      padding: 8px 8px;
    text-align: initial;
    font-family: 'Roboto-Regular';
    font-weight: bold;
}
.profile-header  .dropdown-menu a:hover{
    color: #D60012;
}

.navbar-nav li:hover > ul.dropdown-menu {
    display: block;
}
.dropdown-submenu {
    position:relative;
}
.dropdown-submenu>.dropdown-menu {
    top:0;
    left:100%;
    margin-top:-6px;
}

.dropdown-item.active, .dropdown-item:active {
    color: #000;
    text-decoration: none;
    background-color: #e5eaef;
}

/* rotate caret on hover */
.dropdown-menu > li > a:hover:after {
    text-decoration: underline;
    transform: rotate(-90deg);
} 

/* ---  */
.nav-bottom .navbar-left li{
    font-size: 16px;
    color: #FFFFFF;
    font-family: Roboto-Regular;
    padding: 8px;
}
.navbar-dark .navbar-nav .nav-link {
    color: #FFFFFF;
}
.nav-bottom .navbar-left{
    margin-left: 10px;
    /* margin-right: 126px; */
    /* margin-right: 190px; */


}


.nav-bottom .navbar-left .head-logo{
    height: 70px;
    width: 70px;
    overflow: hidden;
}
.nav-bottom .navbar-left .head-logo img{
    height: 70px;
    width: 70px;
    overflow: hidden;
}
.nav-bottom .icon-style{
    color: #FFFFFF;
}
.profile-mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile-mainContainer .name{
color: #ffff;
margin-left: 4px;
}
/* -----  */


.header-customItems{
    color: #767676;
    font-family: 'Roboto-Regular';
    font-size: 14px;
    /* border-bottom: 1px solid #6a6a6a1f ; */
}
.border-bottomStyle{
    border-bottom: 1px solid #6a6a6a1f ;
}

.header-subCate{
    font-size: 12px;
    font-family: 'Roboto-Bold';
    color: #080808 !important;
    text-align: center;
}

.dropdown-menu.show {
    padding: 4px 5px;
}

.nav-ul ul {
    display: flex;
    list-style: none;
    margin: 0;
}
section.nav-top {
    background-color: #D60012;
    z-index: 9999999;
}
.for-color {
    color: #DB0319;
}
.nav-para p {
    color: #ffff;
    margin: 0;
}
.nav-main-bar ul {
    display: flex;
    list-style: none;
}
.nav-main-bar ul li a {
    text-decoration: none;
    padding: 20px;
}
.nav-social-icons ul {
    display: flex;
    list-style: none;
    padding: 0;
}
.nav-main-bar ul li a {
    font-size: 14px;
    text-decoration: none;
    /* padding: 30px; */
}
section.nav-bottom .row {
    display: flex;
    align-items: center;
}
.nav-ul li a {
    color: #ffffff;
}
.nav-bottom .profile {
    width: 30px;
    height: 30px;
    background-color: aliceblue;
    overflow: hidden;
    border-radius: 50%;
}
section.nav-bottom {
    /* position: absolute; */
    width: 100%;
    background-color: #000;
    /* padding: 5px 0px; */
    /* padding: 10px; */
    padding-top: 20px;
    padding-bottom: 20px;
}
.nav-main-bar ul li a {
    font-size: 14px;
    color: #ffff;
}
section.nav-bottom .row {
    display: flex;
    align-items: center;
}
.banner-text {
    position: absolute;
    top: 270px;
    left: 235px;
    bottom: unset;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* End of Header Css  */

/* Begin Of banner section  */
.banner-stuff h2 {
    font-size: 42px;
    color: #ffffff;
}
.banner-stuff p {
    font-size: 16px;
    color: #ffffff;
    font-family: Poppins-Regular;
    font-weight: 400;
}
.div-banner-submit a {
    font-size: 12px;
    color: #ffffff;
    border: 1px solid;
    padding: 1;
    padding: 10px 30px;
    margin-right: 20px;
}
.nav-ul svg {
    color: #ffffff;
    padding-right: 10px;
}
.nav-para p {
    font-size: 12px;
    color: #ffffff;
    font-family: Visby-meduim;
    letter-spacing: 1px;
}
section.nav-top .row {
    display: flex;
    align-items: center;
}
.nav-ul {
    margin-top: 10px;
    float: right;
    margin-bottom: 6px;
}
.social-ul li {
    margin: 0px 3px;
}
.nav-social-icons ul li a {
    font-size: 20px;
    padding-right: 24px;
    color: #ffffff;
}
.nav-main-bar ul li a {
        font-size: 14px;
        color: #ffffff;
        font-family: Roboto-Regular;
}
li.for-border {
    /* border-right: 1px solid; */
    padding-right: 30px;
}
.nav-social-icons {
    float: right;
}
/* End of Banner section  */


/* Begin of Small section  */
.Small-img img {
    width: 20%;
}
.Small-text h3 {
    font-size: 24px;
    color: #000;
    font-family: Ubuntu-Meduim;
}
section.Small-sec {
    background-color: #104042;
}
/* End Of Small section  */



/* Banner section  */
.Banner-container{  
    width: 100%;
}

.Banner-container .slick-slider{
    height: 100vh;
    width: 100%;
}
.banner-slider{
    height: 100vh;
    width: 100%; 
}
.banner-slider img{
    background-repeat: no-repeat;
    background-size: cover;
        height: 100vh;
        width: 100%; 
}

.banner-slider .contentDv {
    position: absolute;
    bottom: 0;
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding-left: 60px;
    top: 50px;
}

.banner-slider .title{
    color: #FFFFFF;
    font-size: 96px;
    font-family: Roboto-Bold;
    margin: 0px;
}
.banner-slider .sub-title{
    color: #FFFFFF;
    font-size: 73px;
    font-family: Roboto-Bold;
    margin: 0px;
}

.banner-slider .personality{
    color: #EC0014;
    font-family: Authentic Signature;
    font-size: 158px;
    margin: 0px;
}

.banner-slider .para {
    font-size: 17px;
    color: #FFFFFF;
    font-family: Roboto-Regular;
    margin: 0px;
}

.banner-slider .content-inner{
    /* padding: 8px 70px; */
    padding: 8px 118px;
}
.banner-slider .para span{
  color: #EC0014;
  font-family: Roboto-Italic;
  text-decoration: underline;
}

.banner-slider .slick-prev {
    left: 0px !important;
    display: none;
}
.banner-slider .slick-next {
    right: 0px !important;
    display: none;
}
.banner-slider .slick-prev  {
    display: none !important;
}
.banner-slider .slick-next  {
    display: none !important;
}

/* Banner section  End*/

/* Top Sub Slider  */

.top-subSlider {
    /* margin-top: -175px; */
    position: relative;
    bottom: 180px;
}
.top-subSlider .slick-dots li.slick-active button:before {
    opacity: .75;
    color: white;
}
.top-subSlider .slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.top-subSlider .ptext {
    font-size: 18px;
    color: #ffff;
    font-family: Roboto-Bold;
    margin-left: 22px;
    margin-bottom: 0px;
}
.top-subSlider .sub-header-text{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.top-subSlider .plink {
    color: #D60012 !important;
    font-family: Roboto-Regular;
    font-size: 17px;
    margin-right: 22px;
    margin-bottom: 0px;
}
.top-subSlider .main-container{
    height: 300px;
    padding: 8px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-subSlider .main-container h4{
    height: 300px;
    padding: 8px 20px;
}

.top-subSlider .slick-prev {
    left: 19px !important;
    z-index: 1;
}
.top-subSlider .slick-next {
    right: 30px!important;
}

.top-subSlider .category-container {
    position: absolute;
    /* width: 93%; */
    /* top: 92px; */
}

.top-subSlider .image-nextPrev {
    width: 30px;
}

.top-subSlider .slick-prev:before{
    display: none;
}
.slick-next:before {
    display: none;
}

.top-subSlider .title{
    font-size: 14px;
    font-family: Roboto-Bold;
    color: #FFFFFF;
    margin: 0px;
    text-align: center;
}
.top-subSlider .subTitle{
    font-size: 32px;
    font-family: Roboto-Bold;
    color: #EC0014;
    margin: 0px;
    text-align: center;
}

.top-subSlider .subTitle span{
    font-size: 32px;
    font-family: Roboto-Bold;
    color: #ffffff;
    margin: 0px;
    text-align: center;
}

/* Top Sub Slider  end*/
.navbar-expand-md .navbar-collapse {
    /* display: -webkit-box!important; */
    display: -ms-flexbox!important;
    display: flex!important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    justify-content: center !important;
}

.navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    /* text-align: center; */
    justify-content: center !important;
}

@media (max-width: 1366px) { 
    .banner-slider .title {
        font-size: 56px;
    }
    .banner-slider .sub-title {
        font-size: 56px;
    }
    .banner-slider .personality {
        font-size: 98px;
    }
    .banner-slider .para {
        font-size: 13px;
    }
}

@media (min-width: 1200px) { 
    .top-subSlider {
        /* margin-top: -175px; */
        position: relative;
        bottom: 0;
        /* bottom: 182px; */
        padding: 70px 0px;
    }
    .banner-slider .contentDv {
        position: absolute;
        bottom: 0;
        height: 100%;
        display: flex;
        align-items: flex-start;
        padding-left: 60px;
        top: 30px;
    }
    .banner-slider .content-inner {
        /* padding: 8px 70px; */
        padding: 0px 118px;
    }
}

@media (max-width: 1100px) {
    .top-subSlider {
        /* margin-top: -175px; */
        position: relative;
        bottom: 0;
        padding-top: 50px;
    }
    .top-subSlider .subTitle span {
        font-size: 15px;
    }
    .top-subSlider .subTitle {
        font-size: 15px;
    }
    .top-subSlider .title {
        font-size: 10px;
    }
}

@media (max-width: 1150px) {
    .banner-slider .content-inner {
        padding: 8px 76px;
    }
    /* .top-subSlider {
        bottom: 130px;
    } */
    .top-subSlider .main-container {
        height: 230px;
    }
    .banner-slider .para {
        font-size: 15px;
    }
    /* .banner-slider .personality {
        font-size: 116px;
    } */
    /* .banner-slider .sub-title {
        font-size: 63px;
    } */
    /* .banner-slider .title {
        font-size: 85px;
    } */
    .nav-bottom .navbar-left {
        margin-left: 0px;
        margin-right: 100px;
    }
}

@media (max-width: 1024px) {
    .banner-slider .content-inner {
        padding: 8px 15px;
    }
    .AddsBanner-container .inner-container {
        padding: 46px 437px 80px 130px;
    }
    .AddsBanner-container .inner-container h4 {
        font-size: 22px;
    }
  
}
@media (max-width: 991px) {
    .logo-width{
        width: 90px;
    }
    .nav-bottom .navbar-left li {
        font-size: 14px;
        padding: 6px;
    }
    .nav-bottom .navbar-left {
        margin-right: 22px;
    }
}
@media (max-width: 820px) {
    .banner-slider img {
        height: auto;
    }
    .AddsBanner-container .inner-container h4 {
        font-size: 13px !important;
    }
}


@media (max-width: 768px) {
    .AddsBanner-container .inner-container h4 {
        font-size: 13px;
    }
    .AddsBanner-container .inner-container {
        padding: 27px 318px 26px 62px;
    }
    .top-subSlider .main-container {
        height: 178px;
    }
    .top-subSlider {
        bottom: 125px;
    }
    .navbar-brand img {
        width: 120px;
    }
    .nav-bottom .navbar-left {
        margin-left: 0px;
        /* margin-right: 12px; */
        margin-right: 18px;
    }
    .nav-bottom .navbar-left li {
        padding: 0px;
    }
    .banner-slider .contentDv {
        padding-left: 38px;
    }
    .banner-slider .title {
        font-size: 60px;
    }
    .banner-slider .sub-title {
        font-size: 45px;
    }
    .banner-slider .personality {
        font-size: 84px;
    }
    .banner-slider .para {
        font-size: 11px;
    }
    .top-subSlider .subTitle {
        font-size: 20px;
    }
    .top-subSlider .subTitle span {
        font-size: 20px;
    }
    .top-subSlider .title {
        font-size: 12px;
    }
}
@media (max-width: 575px) {
    .nav-para p {
        font-size: 8px;
    }
    .banner-slider .contentDv {
        padding-left: 15px;
    }
    .banner-slider .personality {
        font-size: 20px;
    }
    .banner-slider .sub-title {
        font-size: 10px;
    }
    .banner-slider .title {
        font-size: 14px;
    }
    .nav-para p {
    font-size: 8px;
}

}

@media (max-width: 540px) {
    .AddsBanner-container .inner-container {
        padding: 8px 193px 8px 66px;
    }
    .AddsBanner-container .inner-container h4 {
        font-size: 11px;
    }
    .btn-primary {
        padding: 5px 20px !important;
        font-size: 10px;
    }
    .AddsBanner-container .button-container {
        width: 90%;
    }
    .navbar-right {
        align-items: initial;
    }
    .profile-header{
        display: flex;
    }
}


@media (max-width: 425px) {
    .top-subSlider .slick-next {
        right: 8px!important;
    }
    .AddsBanner-container {
        display: none;
    }
    .top-subSlider .ptext {
        font-size: 12px;
    }
    .top-subSlider {
        bottom: 0px;
    }
    .top-subSlider .main-container {
        height: 92px;
    }
    .top-subSlider .image-nextPrev {
        width: 10px;
    }
    .banner-slider .content-inner {
        padding: 2px 12px;
    }
    .banner-slider {
        height: auto;
    }
    /* .banner-slider img {
        height: 35vh;
    } */
    .banner-slider .title {
        font-size: 28px;
    }
    .banner-slider .sub-title {
        font-size: 18px;
    }
    .banner-slider .personality {
        font-size: 33px;
    }
    .banner-slider .contentDv {
        top: 0px;
    }
    .banner-slider .para {
        font-size: 7px;
    }
    .Banner-container .slick-slider {
        height: auto;
    }
  
    .top-subSlider .subTitle span {
        font-size: 11px;
    }
    .top-subSlider .subTitle {
        font-size: 11px;
    }
    .top-subSlider .title {
        font-size: 10px;
    }
    .navbar-right {
        align-items: initial;
    }
    .profile-header{
        display: flex;
    }
}

@media (max-width: 375px) {
    .top-subSlider {
        margin-top: 6px;
    }
    .banner-slider .title {
        font-size: 30px;
    }
    .banner-slider .sub-title {
        font-size: 20px;
    }
    .banner-slider .personality {
        font-size: 40px;
    }
    .banner-slider .para {
        font-size: 6px;
    }
    .top-subSlider .main-container {
        height: 64px;
    }
    .btn-primary {
        padding: 4px 10px !important;
        font-size: 9px;
    }
    .navbar-right {
        align-items: initial;
    }
    .profile-header{
        display: flex;
    }
}
@media (max-width: 320px) {
    .banner-slider .title {
        font-size: 24px;
    }
    .banner-slider .sub-title {
        font-size: 17px;
    }
    .banner-slider .personality {
        font-size: 33px;
    }
    .top-subSlider {
        display: none;
    }
    .banner-slider .contentDv {
        top: 20px;
    }
    .navbar-right {
        align-items: initial;
    }
    .profile-header{
        display: flex;
    }
}
@media (max-width: 280px) {
    .AddsBanner-container {
        /* height: 55vh; */
      
        display: none;
    }
}


