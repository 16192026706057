.login .dark_black_div{
    background-color: #0D0D0D;
    color: white;
}
.login .red_div{
    background-color: #D60012;
    height: 280px;
}
.login .mb-280{
    margin-bottom: 280px;
}
.remeber-me-dv label {
    color: #B1B1B1;
    font-size: 12px;
    margin: 0;
}
.login .form_box{
    /* max-width: 500px ; */
    background-color: white;
    width: 600px;
    margin: auto;
    padding: 2.5rem;
    position: relative;
    top: -69%; 
}
.login .pos-rel{
    position: relative;
}
.login .d-flex{
    display: flex;
}
.login .by_creating_acc{
    display: flex;
    justify-content: center;
}
.login .font-roboto-bold{
    font-family: Roboto-Bold;
}
.login .font-roboto-light{
    font-family: Roboto-Regular;
}
.login .font-roboto-medium{
    font-family: Roboto-Medium;
}
.login .font-mon-semibold{
    font-family: Montserrat-SemiBold;
}
.login .font-mon-medium{
     font-family: Montserrat-Medium;
}
.login .font-pop{
    font-family: 'Poppins', sans-serif !important;
}
.login .font-mon{
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
}
.login .form-signup{
    max-width: 500px;
    margin: auto;
    text-align: left;   
}
.login .signup-fld , .signup-fld:hover , .signup-fld:active , .signup-fld:focus{
    background: transparent;
    color: white;  
}
.login .clr-red{
    color:#D60012;
}
.login .mt-4rem{
    margin-top: 4rem!important;
}
.login .text-right{
    text-align: right;
}
.login .txt-area{
    resize: none;
}
.login .view_more{
    background-color: #D60012;
    color: white;
    padding: 7px 45px;
    border-radius: 0px;
    transition: 0.06;
}
.login .border-red{
    width: auto;
    height: 44px;
    border: 1px solid transparent;
    float: left;
}
.login .border-red:hover{
    border-color: white!important;
}
.login .border-red:hover .view_more {
    position: relative;
    top: -4px;
    left: -4px;
    transition: 0.6s;
}
.login .btn:hover {
    color: white;
}
.login .med-ps-5{
    padding-left:3rem ;
}
.login select.form-control { -webkit-appearance: menulist; }

.login .dont-text {
    color: white;
    margin-top: 49px !important;
    display: block;
    margin-bottom: 16px;
}

.login .desc-right {
    font-size: 14px;
}
.forget-password-dv a {
    font-size: 11px;
    color: #B1B1B1 !important;
    font-family: 'Poppins-Regular' !important;
}
.remeber-me-dv {
    padding-left: 20px;
}
.remmber-and-forget-dv {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
}
.remeber-me-dv label {
    color: #B1B1B1;
    font-size: 12px;
}
/* media queries */
@media only screen and (max-width: 1366px){
}
@media only screen and (max-width: 1280px){
}
@media only screen and (max-width: 1100px){
}
@media only screen and (max-width: 1024px){
}
@media only screen and (max-width: 991px){
    .login .pad-res-lft-rght-login{
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .login .pe-res-login{
        padding-right: 0.5rem!important;
    }
    .login .mt-4rem {
        margin-top: 2rem!important;
    }
    .login .media-mt-4{
        margin-top:1.5rem;
    }
    .login .form_box {
        width: auto;
        top: -56%;
    }
    .login .red_div {
        height: 350px;
    }
    .login .med-ps-5{
        padding-left:1rem ;
    }
    .login .f-15{
        font-size: 15px;
    }
    .login .f-13{
        font-size: 15px;
    }
    .login .f-16{
        font-size: 13px;
    }
    .login .f-31{
        font-size: 20px;
    }
    .login .f-14{
        font-size: 14px;
        font-weight: 400;
    }
    .login .f-21{
        font-size: 21px;
        font-weight: 400;
    }
    .login .f-22{
        font-size: 17px;
    }
}
@media only screen and (max-width: 820px){
}
@media only screen and (max-width: 768px){
}
@media only screen and (max-width: 575px){
    .remmber-and-forget-dv {
        display: block;
        justify-content: space-between;
        padding-top: 15px;
    }
    
}
/* media queries */

