
.dashboard {
    margin-top: 100px;
    margin-bottom: 50px;
}
.dashboard .page-name{
    font-size: 30px;
    font-family: Roboto-Bold;
    color: #ffff;
}
.dashboard .sidebar{
    margin-top: 40px;
}
.dashboard .sidebar ul li {
    list-style: none;
    list-style: none;
    padding: 18px 12px;
}

.dashboard .sidebar ul li a {
    color: #fff;
    font-size: 16px;
    font-family: Roboto-Medium;
    text-transform: capitalize;
}

.dashboard .active{
    border-left: 1px solid #D60012;
}

/* profile  */
/* .Profile {
    
} */
.Profile .info-container {
    border-bottom: 1px solid #d60012;
}
.Profile .info {
    font-family: Roboto-Regular;
    font-size: 12px;
    color: #ffffff;
    margin: 0;
    margin-top: 6px;
    margin-bottom: 10px;
    text-transform: capitalize;
}
.Profile ::placeholder{
 color: #8F8F8F;
}
.Profile .add-Address {
    width: 100%;
    padding: 7px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #8F8F8F;
}
.Profile .address-icon {
    color: #000;
    margin-left: 6px;
}
.Profile .header{
    display: flex;
    align-items: center;
}
.Profile .header .profile-img {
    height: 82px;
    width: 82px;
    border-radius: 50%;
    overflow: hidden;
}  

.Profile .header .name{    
    font-family: Poppins-SemiBold;
    font-size: 22px;
    color: #D60012;
    margin: 0;
    margin-left: 12px;
}
.Profile .header .sub-title{    
    font-family: Poppins-Regular;
    font-size: 16px;
    color: #ffff;
    margin: 0;
    margin-left: 12px;
}

.Profile  .sub-title2 {
    font-family: Poppins-Regular;
    font-size: 14px;
    color: #ffff;
    margin: 0;
    margin-bottom: 20px;
    text-align: end;
    cursor: pointer;
}

.Profile .sub-title2:hover{  
    text-decoration: underline;
}


.Profile  input {
    width: 100%;
    padding: 7px 16px;
    border: 1px solid #d60012;
    background-color: transparent;
    color: #ffffff;
    border-radius: 5px;
    margin-top: 10px;
    outline: none;
}

.Profile .input-label {
    font-size: 16px;
    font-family: Roboto-Medium;
    color: #ffff;
    margin: 0;
}

.Profile .user-fields{
    /* padding: 55px 20px; */
    padding: 55px 0px;
}
.Profile .save-container{
    padding: 0px 15px;
}
/* .Profile .saveBtn{
    color: #fff;
    background-color: #d60012;
    padding: 8px 38px;
    float: right;
} */
.back-btn button {
    margin-right: 10px;
}
.Profile .btn-primary {
    border-radius: unset !important;
    color: #fff !important;
    background-color: #d60012 !important;
    border-color: #d60012 !important;
    padding: 8px 38px !important;
    margin-top: 29px;
    font-size: 14px;
    font-family: Roboto-Regular;
    float: right;
}

.Profile button.btn.btn-primary {
    position: relative;
    z-index:2;
}

.Profile button.btn.btn-primary:before {
    content: '';
    background: transparent;
    width: 100%;
    height: 100%;
    border: 2px solid #d60012;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.6s;
    z-index:-1;
}

.Profile button.btn.btn-primary:hover:before {
    left: 12px;
    top: 12px;
}


/* .Profile .saveBtn:hover {
    color: rgb(2, 2, 2);
    background-color: #fff;
    padding: 8px 38px;
    float: right;
} */

.Profile .edit-container{
        position: absolute;
        bottom: 2px;
        left: 70px;
        color: #ffff;
        height: 26px;
        width: 26px;
        border-radius: 50%;
        background-color: #d60012;
        text-align: center;
    }
.Profile .edit-container input {
    width: 100%;
    padding: 4px 27px;
    position: absolute;
    opacity: 0;
    left: -28px;
    top: -7px;
}
.Profile .flex-style {
    display: flex;
    align-items: center;
}
/* profile  End */
/* media queries */
@media only screen and (max-width: 1366px){
}
@media only screen and (max-width: 1280px){
}
@media only screen and (max-width: 1100px){
}
@media only screen and (max-width: 1024px){
}
@media only screen and (max-width: 991px){
}
@media only screen and (max-width: 820px){
}
@media only screen and (max-width: 768px){
}
@media only screen and (max-width: 575px){
    .sidebar ul {
        padding: 0;
    }
    .Profile .info-container {
        border-bottom: 1px solid #d60012;
        padding: 0px 10px;
    }
    .Profile .user-fields {
        padding: none !important;
    }
    .Profile .header {
        display: block;
        align-items: center;
    }
    .Profile .header {
        display: block;
    }
    .Profile .header .sub-title {
        font-size: 10px;
        
    }
    .Profile .header .name {
        font-size: 15px;
        
    }
    .nav-para p {
        font-size: 8px;
    }
    .div-address-btn button {
      
        width: 100% !important;
       
    }
    .all-check-out-record p {
        font-size: 10px;
    }
    .all-check-out-record span {
        font-size: 10px;
    }
    .bookmark .tableDv {
      
        overflow: scroll;
    }
    .bookmark .button-group {
        margin-top: 20px;
        text-align: center;
    }
    .dashboard .row {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
    .Profile .header .name {
        
        margin-left: 6px;
    }
    .Profile .edit-container {
        position: absolute;
        bottom: 100px;
        left: 90px;
        color: #ffff;
        height: 26px;
        width: 26px;
        border-radius: 50%;
        background-color: #d60012;
        text-align: center;
    }
    .Profile button.btn.btn-primary {
        position: relative;
        z-index: 2;
        margin-right: 0;
    }
    .Profile .user-fields {
        /* padding: 55px 20px; */
        padding: 0;
    }
    .Profile  .sub-title2 {
        
        margin: 0;
    }
    .dashboard {
        margin-top: 40px;
    }
    .address-modal {
        width: 100%;
    }
    .bookmark table tr.topbar th {
        font-size: 11px;
        text-align: center;
        white-space: nowrap;
    }
    .bookmark .heading {
        padding-bottom: 0 !important;
    }
    .bookmark .tableDv {
        margin: 0 0px;
        padding: 35px 0px;
    }

}

/* @media (max-width: 768px) {
    .Profile input {
        width: 100%;
        padding: 7px 16px;
    }
}

@media (max-width: 425px) {
    .dashboard .sidebar ul li {
        display: inline-block;
    }
    .Profile .header .name {
        font-size: 18px;
    }
    .Profile {
        margin-top: 25px;
    }
}

@media (max-width: 320px) {
    .dashboard .sidebar ul li a {
        font-size: 12px;
    }
    .dashboard .sidebar ul li {
        padding: 15px 12px;
    }
    .Profile .saveBtn {
        padding: 4px 15px;
    }
    .Profile .edit-container {
        left: 54px;
        height: 20px;
        width: 20px;
        font-size: 12px;
    }
    .Profile .header .profile-img {
        height: 65px;
        width: 65px;
    }
} */

/* Begin of Other Css */
.address-input {
    margin: 10px 0px;
}
.address-input input {
    padding: 10px;
}
.address-input input {
    padding: 15px;
    background-color: transparent;
    border: 1px solid #d4001c;
    outline: none;
    color: #ffffff;
}
.add-address-button button {
    width: 100%;
    padding: 14px;
    text-transform: capitalize;
    font-family: 'Poppins';
    background-color: #d4001c;
    border: none;
    color: #ffffff;
    font-weight: 700;
}
.add-address-button {
    margin-top: 10px;
}
.radio_add_box.p-3.pt-4.d-flex.mb-3 {
    background-color: transparent;
    margin-top: 20px;
    border: 1px solid #d20023;
    color: #ffffff;
    border-radius: 5px;
}
.pre-delete {
    margin-right: 10px;
    z-index: 1;
}
.all-check-out-record span {
    text-transform: capitalize;
}
.div-address-btn button {
    background-color: #d20023;
    color: #ffffff;
    width: 43%;
    padding: 10px;
    border: unset;
    border-radius: 7px;
}
.pre-delete i {
    cursor: pointer;
}
/* ================= */
