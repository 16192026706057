.POL .mt-4rem{
    margin-top:4rem;
    margin-bottom: 4rem;
}
.POL .dark_black_div{
    background-color: #0D0D0D;
    color: white;
}

.POL .font-roboto{
    font-family: Roboto-Regular;
}

.POL .f-16{
    font-size: 16px;
}

.POL .pt-5rem{
    padding-top: 5rem!important;
}

.POL select.form-control { -webkit-appearance: menulist; }


/* media queries */
@media only screen and (max-width: 1366px){
}
@media only screen and (max-width: 1280px){
}
@media only screen and (max-width: 1100px){
}
@media only screen and (max-width: 1024px){
}
@media only screen and (max-width: 991px){
}
@media only screen and (max-width: 820px){
}
@media only screen and (max-width: 768px){
}
@media only screen and (max-width: 575px){
    .dark_black_div h3 {
        font-size: 13px;
    }
    .dark_black_div p {
        font-size: 10px;
    }
}
/* media queries */
@media only screen and (max-width: 990px){
    .POL .media-mt-4{
        margin-top:1.5rem;
        margin-bottom: 1.5rem;
    }
}


