/* Blog Css Start Here */
.blogs_details {
    padding: 60px 0;
  }
  .blogs_details .detail {
    padding: 40px 0px;
  }
  .blogs_details .detail .title {
    padding-bottom: 25px;
  }
  .blogs_details .detail .title h3 {
    color: #ffff;
  }
  .ClientsReviewsSec .info .name {
    font-size: 14px;
    color: #ffff;
    font-family: Poppins-Regular;
  }
  .ClientsReviewsSec .info .date {
    font-size: 14px;
    color: #ffff;
    font-family: Poppins-Regular;
  }

  /* media queries */
@media only screen and (max-width: 1366px){
}
@media only screen and (max-width: 1280px){
}
@media only screen and (max-width: 1100px){
}
@media only screen and (max-width: 1024px){
}
@media only screen and (max-width: 991px){
}
@media only screen and (max-width: 820px){
}
@media only screen and (max-width: 768px){
}
@media only screen and (max-width: 575px){
}
  @media (max-width: 1100px) {
    .blogs_details .detail .title h3 {
      font-size: 30px;
      line-height: 40px;
    }
  }
  @media (max-width: 575px) {
    .blogs_details {
      padding: 30px 0;
    }
    .blogs_details .detail {
      padding: 10px 0px;
    }
    .blogs_details .detail .title h3 {
      font-size: 25px;
      line-height: 35px;
    }
    .blogs_details .detail p {
      font-size: 14px;
      text-align: justify;
    }
  }
  @media (max-width: 480px) {
    .blogs_details .detail .title h3 {
      font-size: 22px;
      line-height: 32px;
    }
  }
  /* Client Comments Start Here */
  .ClientsReviewsSec {
    /* padding: 65px 2px; */
    padding: 56px 2px;
  }
  .ClientsReviewsSec .head {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ClientsReviewsSec .head h3 {
    color: #FFFFFF;
    font-size: 26px;
    font-family: Poppins-SemiBold;
  }
  .ClientsReviewsSec .head h5.total-comments {
    color: #FFFFFF;
    font-size: 18px;
    font-family: Poppins-SemiBold;
  }
  .ClientsReviewsSec .brieflyReview {
    display: flex;
    align-items: flex-start;
  }
  .ClientsReviewsSec img {
    width: 70px;
    border-radius: 60px;
    height: 70px;
}
  .ClientsReviewsSec .brieflyReview .img-box {
    /* min-width: 80px; */
    border-radius: 50%;
    height: 80px;
    width: 80px;
  }
  .ClientsReviewsSec .brieflyReview .contentDv {
    padding-left: 20px;
  }
  .ClientsReviewsSec .brieflyReview .contentDv .info {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }
  .ClientsReviewsSec .brieflyReview .contentDv .info .spacer {
    padding: 0 10px;
  }
  .ClientsReviewsSec .brieflyReview .contentDv .info h6 {
    margin-bottom: 0;
  }
  .ClientsReviewsSec .brieflyReview .descrip {
    padding-right: 20%;
  }
  .ClientsReviewsSec .brieflyReview .descrip p {
    color: #ffff;
    font-family: Poppins-Regular;
    font-size: 13px;
}
  .ClientsReviewsSec .brieflyReview .rating .rply-btn a {
    font-size: 13px;
    font-family: Poppins-Regular;
    color: #ffff;

  }
  .ClientsReviewsSec .replyDv .brieflyReview .img-box {
    min-width: 90px;
  }
  .ClientsReviewsSec .replyDv {
    background: #f5f5f5;
    padding: 10px 30px 0px;
    margin-left: 120px;
    margin-top: 30px;
    margin-bottom: 60px;
  }
  .ClientsReviewsSec .replyDv .brieflyReview {
    padding: 15px 0;
    border-bottom: 1px solid #e0e0e0;
  }
  .ClientsReviewsSec .replyDv .brieflyReview:last-child {
    border: 0;
  }
  .ClientsReviewsSec .replyDv .brieflyReview .descrip p {
    margin-bottom: 0;
  }
  .ClientsReviewsSec .border-topp {
    border-top: 1px solid #e3e3e3;
  }
  @media (max-width: 991px) {
    .ClientsReviewsSec .replyDv {
      margin-left: 30px;
    }
    .ClientsReviewsSec .brieflyReview .descrip {
      padding-right: 0%;
    }
  }
  @media (max-width: 575px) {
    .ClientsReviewsSec .head {
      display: block;
      text-align: center;
    }
    .ClientsReviewsSec .head h3 {
      font-size: 30px;
      line-height: 40px;
    }
    .ClientsReviewsSec .replyDv {
      margin-left: 0px;
    }
    .ClientsReviewsSec img {
      width: 70px;
    }
    .ClientsReviewsSec .brieflyReview {
      display: block;
      text-align: center;
    }
    .ClientsReviewsSec .replyDv .brieflyReview {
      display: block;
      text-align: center;
    }
    .ClientsReviewsSec .brieflyReview .descrip p {
      text-align: justify;
      text-align-last: center;
    }
    .ClientsReviewsSec .brieflyReview .contentDv {
      padding-left: 0px;
    }
    .ClientsReviewsSec .brieflyReview .contentDv .info {
      justify-content: center;
    }
  }
  /* Client Comments Start Here */
  
  .leave_comment {
    padding: 20px 0 70px;
  }
  .leave_comment .head h3 {
    color: #ffff;
    padding-bottom: 20px;
  }
  .leave_comment .form-control {
    border: 1px solid #0000003b;
    border-radius: 0;
    margin-bottom: 15px;
    padding: 14px 20px;
  }
  .leave_comment ::placeholder {
    color: #000000 !important;
    font-size: 15px;
   
  }

  .leave_comment textarea {
    height: 130px;
    resize: none;
  }
  .leave_comment button {
    /* width: 100%; */
    padding: 9px 70px;
    background: #D60012 !important;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    border-radius: 0px;
    /* border: 2px solid #fff; */
    transition: 0.6s;
}
  .leave_comment button:hover {
    /* box-shadow: 0px 0px 0px 2px #D60012; */
  }
  @media (max-width: 575px) {
    .leave_comment {
      padding: 30px 0 50px;
    }
  }
  @media (max-width: 480px) {
    .leave_comment .head h3 {
      font-size: 30px;
      line-height: 40px;
    }
  }
  @media (max-width: 320px) {
    .ClientsReviewsSec .brieflyReview .descrip p {
      font-size: 10px;
  }
  .ClientsReviewsSec {
    padding: 65px 0px;
  }
  .leave_comment .head h3 {
    font-size: 22px;  
  }
  }
  /* Blog Css End Here */
  