.form-gift-car-input input {
    width: 100%;
    padding: 15px 10px;
    margin: 10px 0px;
    background-color: transparent;
    border: 1px solid #ffffff;
    border-radius: 5px;
    color: #ffffff;
}

.form-gift-car-input textarea {
    margin: 10px 0px;
    height: 160px;
    background-color: transparent;
    resize: none;
}
.gift-card-sec {
    padding: 100px 0px;
}

.form-gift-car-input input::placeholder {
    font-size: 12px;
    color: #ffffff;
    font-family: 'Roboto';
    letter-spacing: 1px;
}

.form-gift-car-input textarea::placeholder {
    font-size: 12px;
    color: #ffffff;
    font-family: 'Roboto';
    letter-spacing: 1px;
}

.gift-card-get-price {
    display: flex;
    justify-content: space-between;
}

.gift-card-get-price h5 {
    font-size: 16px;
    color: #ffffff;
    font-family: 'Roboto';
}
.gift-card-text p {
    font-size: 16px;
    color: #ffffff;
    font-family: 'Roboto';
    text-transform: capitalize;
    text-align: end;
    margin: 0;
}
.gift-card-get-price {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}
.gift-card-price {
    padding: 14px 10px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    transition: 1s;
    cursor: pointer;
    height: 170px;
}
.gift-card-price:hover {
    background-color: #d60012;
    border: 1px solid #d60012;
}
section.gift-card-sec .row {
    display: flex;
    align-items: baseline;
}
.gift-balance {
    display: flex;
    justify-content: space-between;
}
.gift-balance h5 {
    font-size: 16px;
    color: #ffffff;
    font-family: 'Roboto';
}
.gift-balance h4 {
    font-size: 16px;
    color: #ffffff;
    font-family: 'Roboto';
    text-transform: capitalize;
}
.gift-car-price-two {
    margin-top: 40px;
}
.gift-balance h3 {
    font-size: 16px;
    color: #ffffff;
    font-family: 'Roboto';
    text-transform: capitalize;
    padding-bottom: 30px;
}
.gift-card-input label {
    font-size: 18px;
    color: #ffffff;
    font-family: 'Roboto';
    text-transform: capitalize;
    padding-right: 6px;
}
.gift-card-input input {
    background-color: transparent;
    border: none;
    outline: none;
}
.gift-card-input {
    padding-top: 40px;
    text-align: end;
}

/* media queries */
@media only screen and (max-width: 1366px){
}
@media only screen and (max-width: 1280px){
}
@media only screen and (max-width: 1100px){
}
@media only screen and (max-width: 1024px){
}
@media only screen and (max-width: 991px){
}
@media only screen and (max-width: 820px){
}
@media only screen and (max-width: 768px){
}
@media only screen and (max-width: 575px){
}