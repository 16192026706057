.thank-box h3 {
    font-size: 65px;
    color: #ffffff;
    font-family: 'Roboto-Regular';
}
.thank-box p {
    font-size: 14px;
    color: #ffffff;
    font-family: 'Roboto';
    font-weight: 200;
    letter-spacing: 1px;
    padding-bottom: 20px;
}
.thank-box a {
    font-size: 16px;
    color: #ffffff;
    background-color: #d60012;
    padding: 10px 20px;
    border-radius: 6px;
}
section.tahnk-you {
    padding: 100px 0px;
}
.thank-box {
    border: 1px solid #d60012;
    padding: 40px;
    text-align: center;
}