.followUs .gradient_red{
    background-image:url('../images/follow/fbg1.png') ;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.followUs .f-51{
    font-size: 51px;
}
.followUs .font-roboto{
    font-family: Roboto-Regular;
}
.followUs .f-17{
    font-size: 17px;
}
.followUs .f-14{
    font-size: 14px;
}
.followUs .f-15{
    font-size: 15px;
}
.followUs .f-9{
    font-size: 9px;
}
.followUs .day_colr{
    color: #0D0D0D;
}
.followUs .fb_color{
    color: #0878F6;
}
.followUs .colr_hashtag{
color: #515151;
}
.followUs .icon_facebook{
    border-radius: 50%;
}
.followUs .view_more{
    background-color: #fff;
    color: black;
    padding: 7px 45px;
    border-radius: 0px;
}
.followUs .border-red{
    width: auto;
    height: 44px;
    border: 1px solid transparent;
    float: left;
}
.followUs .border-red:hover{
    border-color: white!important;
}
.followUs .border-red:hover .view_more {
    position: relative;
    top: -4px;
    left: -4px;
}
.followUs .btn:hover {
    color: black;
}
.followUs .card_follow{
    width: auto ;
    max-width: 270px;
}
.followUs .card{
border-radius: 0px;
}
.followUs .d-flex{
    display: flex;
}
.followUs .justify-spacebetween{
    justify-content: space-between;
}
.followUs .align-center{
    align-items: center;
}
.followUs .card-img-top{
    height: 40px;
    width: 40px;
}
.followUs .align-self-center{
    align-self: center;
}

/* media queries */
@media only screen and (max-width: 1366px){
}
@media only screen and (max-width: 1280px){
}
@media only screen and (max-width: 1100px){
}
@media only screen and (max-width: 1024px){
}
@media only screen and (max-width: 991px){
}
@media only screen and (max-width: 820px){
}
@media only screen and (max-width: 768px){
}
@media only screen and (max-width: 575px){
}

@media only screen and (width: 768px){
    .followUs .mt-lpt-3{
       margin-top: 3rem!important;
   }
   .followUs .d-flex{
    justify-content: center;
   }
   .followUs .f-51 {
    font-size: 36px;
    }
    .followUs .f-17 {
        font-size: 14px;
    }
    .followUs .card_follow {
        max-width: 100%;
    }
    .footer .inner-container {
        padding: 35px 35px;
    }
    .footer .blog {
        margin-left: 12px;
    }
}

@media only screen and (max-width: 700px){
    .followUs .f-51{
        font-size: 31px;
    }
    .followUs .f-17{
        font-size: 13px;
    }
    .followUs .res-flx-col{
        flex-direction: column;
    }
    .followUs .mt-res-5{
        margin-top: 3rem;
        margin-right: 0px!important;
    }
    .followUs .no_ms{
        margin-left: 0px!important;
        align-items: center;
    }
}