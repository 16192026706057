/* Testimonial  */
.testimonial-bg .slick-prev:before {
    display: none !important;
}
.testimonial-bg .slick-prev {
    left: 46px !important;
    top: 268px !important;
    z-index: 1;
}
.testimonial-bg .slick-next {
    left: 154px !important;
    top: 268px !important;
    z-index: 1;
}

.testimonial-bg .slick-dots li.slick-active button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    outline: 1px solid white;
    border-radius: 50%;
    margin: 0px 0px 0px 0px;
}
.testimonial-bg .slick-dots li.slick-active button:before {
    opacity: .75;
    color: white !important;
    transform: scale(1);
}
.testimonial-bg .slick-dots li button:before {
    opacity: .75;
    color: white !important;
    transform: scale(1);
}

.testimonial-bg {
    background-image: url('../images/testimonial.png');
    background-size: cover;
}
.testimonial-bg .slick-dots {
    position: absolute;
    bottom: 30px !important;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    margin-left: 42px !important;
    width: 80% !important;
}
.testimonial-bg .slick-dots li {
    float: left !important;
}
.testimonial-bg .slick-dots li.slick-active button:before {
    opacity: .75;
    color: white !important;
}
.testimonial  .right-border {
    height: auto;
    width: 100%;
    border: 1px solid #fff;
    padding: 25px 25px;
}

.testimonial  .right-border .user-profile {
    height: 70px;
    width: 70px;
    background-color: aliceblue;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 22px;
}
.testimonial .right-border .inner-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.testimonial .right-border .user-name {
    color: #FFFFFF;
    font-family: Poppins-Bold;
    font-size: 20px;
    margin: 0px;
    margin-top: 12px;
}
.testimonial .right-border .user-subText {
    color: #FFFFFF;
    font-family: Poppins-Regular;
    font-size: 18px;
}

.testimonial  .right-border  .desc {
    font-size: 13px;
    font-family: Roboto-Regular;
    color: #FFFFFF;
    margin: 25px 0px 0px 0px;
}
.testimonial .right-border .doodle img{
    width: 40px;
    margin-left: 33px;
    margin-bottom: 43px;
}

.testimonial .slide-text {
        padding: 17px 0px 0px 0px;
}
.slide-text h3 {
    font-size: 18px;
    color: #ffffff;
    font-family: 'Ubuntu-Meduim';
    margin: 0;
}
.slide-text h2 {
    font-size: 41px;
    color: #ffffff;
    font-family: Roboto-Bold;
}
.man h4 {
    font-size: 18px;
    color: #ffffff;
    font-family: ''Ubuntu-Meduim'';
    font-family: 'Ubuntu-Meduim';
}
.man h5 {
    font-size: 18px;
    color: #ffffff;
    font-family: ubuntu-light;
}
.man p {
    font-size: 16px;
    color: #FFFFFF;
    font-family: Roboto-Regular;
    letter-spacing: 0px;
}
.man {
    display: flex;
    align-items: center;
}
.slick-prev:before {
    content: '←';
    /* opacity: 0 !important; */
}
.slick-next:before {
    content: '→';
    /* opacity: 0 !important; */
}
.slide-padding {
    padding: 60px 50px;
}

/* / Testimonial End   / */
/* media queries */
@media only screen and (max-width: 1366px){
}
@media only screen and (max-width: 1280px){
    /* .testimonial-bg .slick-dots {
        bottom: 85px !important;
        width: 80%;
    } */
    .slide-padding {
        padding: 60px 8px;
    }
    .man p {
        font-size: 15px;
    }
    .slide-text h2 {
        font-size: 30px;
    }
    .testimonial .right-border .desc {
        font-size: 14px;
    }
    .testimonial .right-border .user-subText {
        font-size: 16px;
    }
    .testimonial .right-border .user-name {
        font-size: 18px;
    }
    .testimonial .right-border .user-profile {
        height: 68px;
        width: 68px;
       
    }
}
@media only screen and (max-width: 1100px){
    .testimonial-bg .slick-dots {
        bottom: 55px !important;
        width: 80%;
    }
}
@media only screen and (max-width: 1024px){
    .testimonial-bg .slick-dots {
       
        margin-left: 0px !important;
    }
}
@media only screen and (max-width: 991px){
    .top-subSlider .subTitle {
        font-size: 19px;
    }
    .top-subSlider .subTitle span {
        font-size: 19px;
    }
    .top-subSlider .title {
        font-size: 10px;
    }
    .newArival-container .para {
        font-size: 12px;
      
    }
}
@media only screen and (max-width: 820px){
}
@media only screen and (max-width: 768px){
    .testimonial .slide-text {
        padding: 35px 0px 0px 14px;
    }
    .slide-text h2 {
        font-size: 22px;
    }
    .testimonial .right-border .user-name {
        font-size: 16px;
    }
    .testimonial .right-border .user-subText {
        font-size: 14px;
    }
    .testimonial .right-border .desc {
        font-size: 12px;
    }
    .testimonial-bg .slick-dots {
        margin-left: 58px !important;
        bottom: 76px !important;
    }
    .testimonial-bg .slick-next {
        left: 154px !important;
        top: 293px !important;
    }
    .testimonial-bg .slick-prev {
        top: 293px !important;
    }
}
@media only screen and (max-width: 575px){
    .newArival-container .para {
        font-size: 8px;
        margin: 0 !important;
    }
    .newArival-container .title {
        font-size: 13px;
    }
    .testimonial-bg .slick-dots {
        margin-left: 35px !important;
        bottom: 12px !important;
    }
    .testimonial-bg .slick-next {
        left: 58px !important;
        top: 581px !important;
    }
    .testimonial-bg .slick-prev {
        top: 580px !important;
        left: 8px !important;
    }
}

/* @media (max-width: 1150px) {
    
} */

/* @media (max-width: 768px) {
    
} */

/* @media (max-width: 575px) {
   
  
} */

/* @media (max-width: 425px) {

    .testimonial-bg .slick-dots {
        margin-left: 35px !important;
        bottom: 12px !important;
    }
    .testimonial-bg .slick-next {
        left: 138px !important;
        top: 495px !important;
    }
    .testimonial-bg .slick-prev {
        top: 495px !important;
        left: 8px !important;
    }
} */

/* @media (max-width: 375px) {
    .testimonial-bg .slick-prev {
        top: 545px !important;
        left: 20px !important;
    }
    .testimonial-bg .slick-next {
        left: 128px !important;
        top: 545px !important;
    }
    .testimonial-bg .slick-dots {
        width: 80%;
    }
}
@media (max-width: 320px) {
    .testimonial-bg .slick-next {
        left: 128px !important;
        top: 605px !important;
    }
    .testimonial-bg .slick-prev {
        top: 605px !important;
        left: 20px !important;
    }
} */

